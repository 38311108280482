import React from "react";

const Dashboard = () => {
  return (
    <div className="w-full p-6">
      <span className="text-base font-bold flex-1">Dashboard</span>
      <br />
    </div>
  );
};

export default Dashboard;
